<template>
  <div class="container mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4" flat>
      <div>
        <p class="text-body-1 font-weight-bold">{{ $t("丢失订单") }}</p>
        <p>
          <v-icon class="" color="primary">mdi-alert-circle-outline</v-icon>
          <span class="textred">{{
            $t(
              "这些是用户在享受折扣时下的订单，即使忘记登录或没有链接您的帐户也无需担心。如果不幸忘记登录，您的订单仍会被系统保存在这里。具体订单请联系客服进行回收。"
            )
          }}</span>
        </p>
      </div>
      <v-row class="mb-4">
        <!-- <v-col xl="4" lg="4" md="12" sm="12" class="d-flex align-center">
          <div class="d-flex mr-4">
            <div style="" class="text-body-1 font-weight-bold mt-2">
              {{ $t("订单状态：") }}
            </div>
            <v-select
              :items="typelist"
              dense
              solo-inverted
              flat
              color="#F2F2F2"
              v-model="searchObj.shop_id"
              :label="$t('类型：')"
              item-value="id"
              item-text="name"
              @change="getOrder"
            ></v-select>
          </div>
        </v-col> -->
        <v-col xl="4" lg="4" md="12" sm="12">
          <div class="d-flex align-center mr-4">
            <div style="width: 60px" class="text-body-1 font-weight-bold">
              {{ $t("时间：") }}
            </div>
            <v-card  max-width="400" color="#F2F2F2" flat class="pl-2 pr-2 pt-1 pb-1 d-flex align-center">

              <v-menu
                v-model="startshow"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="me-2">mdi-calendar-month</v-icon>
                  <span v-bind="attrs" v-on="on" v-if="searchObj.start">{{searchObj.start}}</span>
                  <span  v-bind="attrs" v-on="on"  v-else>{{$t('开始时间')}}</span>
                </template>
                <v-date-picker
                  v-model="searchObj.start"
                  @input="startshow = false"
                />
              </v-menu>
              <v-icon>mdi-minus</v-icon>
              <v-menu
                v-model="endshow"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                   <span v-bind="attrs" v-on="on" v-if="searchObj.end">{{searchObj.end}}</span>
                   <span  v-bind="attrs" v-on="on"  v-else>{{$t('结束时间')}}</span>
                   
                </template>
                
                <v-date-picker v-model="searchObj.end" @input="endshow = false" />
              </v-menu>
              <v-icon>mdi-menu-down</v-icon>
            </v-card>
          </div>
        </v-col>
        <v-col xl="3" lg="3" md="12" sm="12">
          <v-card
            flat
            class="pl-4 d-flex align-center justify-space-between"
            color="#F2F2F2"
          >
            <input
              :placeholder="$t('搜索关键词/订单编号')"
              class=""
              v-model="keyword"
              @keydown.enter="toserach"
            />
            <v-btn class="" width="60" color="primary" @click="toserach">
              <v-icon color="#fff" size="28">mdi-magnify</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <!-- 订单信息 -->
      <div v-if="orderdata && orderdata.length>0" >
        <div class="" v-for="(good, index) in orderdata" :key="index">
          <v-card
            flat
            color="#F2F2F2"
            class="pa-2 d-flex align-center justify-space-between"
          >
            <span class="d-flex">
              <div style="width:40px ">
                <v-img class="mr-4"  src="@/assets/images/webtype/ali.png" v-if="good.from_web=='ali'"></v-img>
                <v-img class="mr-4"  src="@/assets/images/webtype/pinduoduo.png" v-else-if="good.from_web=='pdd'"></v-img>
                
                <v-img class="mr-4"  src="@/assets/images/webtype/tao.png" v-else ></v-img>
              </div>
              <span class="mr-6">{{ $t("订单编号") }}：{{good.trade_parent_id}}</span>
              <span class="mr-6">{{ $t("商品来源") }}：{{good.from_web=='ali' ? '1688' : good.from_web=='pdd' ? t('拼多多') : $t('淘宝')}}</span>
              <span>{{good.create_time | formFilter}}</span>
            </span>
            <span class="text-body-1 text-sharered">{{$t('未申请')}}</span>
          </v-card>
          <v-row class="d-flex align-center mt-2 mb-2">
            <v-col cols="1">
              <div>
                <v-img
                 v-if="good.item_img"
                  width="70"
                  :src="good.item_img"
                ></v-img>
                <v-img  width="70"
                v-else
                  src="@/assets/images/other/goods.png"></v-img>
              </div>
            </v-col>
            <v-col class="9">
              <div class="d-flex justify-space-between">
                <div class="">
                  <div>{{good.item_title}}</div>
                  <div class="text-body-1 grey--text mt-2 mb-2">{{$t('数量')}}</div>
                  <div>{{$t('返利金额')}}</div>
                </div>
                <div>
                  <div class="text-body-1 text-sharered">¥{{good.item_price}}</div>
                  <div class="grey--text mt-2 mb-2">x{{good.item_num}}</div>
                  <div class="text-sharered">¥{{good.fl_money}}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </div>
      <empty class="mt-8" :describe="$t('暂无订单~')" :imgSrc="emptysrc" v-else></empty>
      <div
        
        class="text-center d-flex mt-4"
        align="center"
      >
        <v-row align="center" class="justify-center">
          <span>{{$t('共')}}{{pagearr.pagetotal}}{{$t('页')}}</span>

          <v-pagination
            v-model="pagearr.page"
            :length="pagearr.pagetotal"
            total-visible="5"
            @input="getpagedata(1)"
          />
          <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
          <span class="ml-6 hidden-xs-only">
            {{ $t("跳转至") }}
            <input
              v-model="pagearr.page"
              autocomplete="off"
              style="width: 80px"
              class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
              @input="numpagedata"
              @blur="getpagedata(1)"
            />
            {{ $t("页") }}
          </span>
        </v-row>
       
      </div>
    </v-card>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
export default {
  components: { empty },
  data() {
    return {
      searchObj: {
        end: "",
        start: "",
        shop_id: "",
      },
      typelist: [
        { name: "返利", id: "1" },
        { name: "回扣", id: "2" },
        { name: "分红", id: "3" },
      ],
      orderdata:[],
      param:{},
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal: 50,
        total: 0,
      },
      inputpage:1,
      endshow: false,
      startshow: false,
      keyword: "",
      emptysrc:require('@/assets/images/other/zanwudingdan@2x.png'),
    };
  },
  mounted(){
    this.param["page"] = this.pagearr.page;
    this.param["pagesize"] = this.pagearr.pagenum;
    
    // 
    this.getlossorder()
  },
  methods: {
    //获取丢失订单
    getlossorder() {
      this.$api.order.lossorder(this.param).then((res) => {
        if(res.status=='success'){
          this.orderdata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
          this.orderdata=[]
          this.pagearr.pagetotal = res.total;
        }
      });
    },
    getOrder() {},
    toserach() {
      this.param["sn"] = this.keyword;
      this.param["start"] = this.searchObj.start;
      this.param["end"] = this.searchObj.end;
      this.getlossorder()
    },
    // 点击第几页
    getpagedata(type) {
      if (type === 2) {
        this.pagearr.page = 1;
      }
      this.param["page"] =Number(this.pagearr.page) ;
      this.param["pagesize"] = this.pagearr.pagenum;
      this.getlossorder();
    },
    numpagedata(){
      this.pagearr.page=Number( this.pagearr.page)
    }
  },
};
</script>
<style scoped>
/deep/ .v-pagination .v-pagination__item {
  box-shadow: none;
  background: #f2f2f2;
}
/deep/ .v-pagination__navigation {
  box-shadow: none;
  background: #f2f2f2 !important;
}
input{
  outline: none;
}
</style>
<style>
.textred {
  color: #f63605;
}
.text-sharered {
  color: #f63605;
}
</style>